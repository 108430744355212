import { useEffect } from "react";
import './Footer.css'
import { Box, Flex, Image, Input, Text, Button, Divider } from "@chakra-ui/react";
import trophy from '../../assets/images/trophy 1.svg'
import logo from '../../assets/images/logo.png'
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CategoryModel } from "../../../main/hooks/useCategoryModel";
import Features from "../Features/Features";
import { FaLinkedin } from "react-icons/fa";

export default function Footer() {
    const history = useNavigate()
    const { onChangeValue } = CategoryModel()
    const goToStore = () => {
        onChangeValue(undefined)
        history('/home/loja/')
    }

    const goToLink = (url: string) => {
        window.open(url, "_blank");
    }

    return (
        <footer id="pagFooter">
            <Box padding={['0 2rem 3rem 2rem', '0 5rem 3rem 5rem', '0 8rem 3rem 8rem', '0 10rem 3rem 10rem', '0 17rem 3rem 17rem']}>
                <Features />
                <Flex mt='-4rem' as='section' flexWrap='wrap' gap='3.5rem' mb='3rem' justifyContent='space-between' w='100%'>
                    <Flex direction='column' w={['100%', '100%', '100%', '75%', '75%']} alignItems='flex-start'>
                        <Image height='3.5rem' objectFit='contain' src={logo} />
                        <Text mt='2rem' as='span'>"Explore novos horizontes com nossas bicicletas de qualidade premium. Encontre a sua aventura sobre duas rodas hoje!"</Text>
                        <Text mt='2rem' className="text-primary" fontSize='1.6rem'>Contato</Text>
                        <Flex alignItems='center' gap='1rem' mt='2rem'>
                            <FaMapMarkerAlt />
                            <Text as='span'>R. São Pedro, Salesiano. 1537, Juazeiro do Norte - CE, 63010-010</Text>
                        </Flex>
                        <Flex alignItems='center' gap='1rem' mt='1rem'>
                            <IoMdMail />
                            <Text as='span'>casadabicicleta@gmail.com</Text>
                        </Flex>
                    </Flex>


                    <Flex direction='column' w={['100%', '100%', '100%', '15%', '15%']} >
                        <Text className="text-primary" fontSize='1.6rem'>Menu</Text>
                        <Flex direction='column' className='footer-links' mt='2rem' gap='1rem'>
                            <Text as='span' onClick={() => history('/home/')}>Home</Text>
                            <Text as='span' onClick={() => goToStore()} >Loja</Text>
                            <Text as='span' onClick={() => history('/contato/')} >Contato</Text>
                            <Text as='span' onClick={() => history('/localizacao/')} >Localização</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
            <Box id="border">
            </Box>

            <Flex id='copyright-holder' flexWrap='wrap'  alignItems='center' justifyContent='flex-start' gap='0.2rem' padding={['2rem', '2rem 5rem', '2rem 8rem', '2rem 10rem', ' 2rem 17rem']}>
                <Text className="copyright text-secundary"
                    fontSize='0.875rem' onClick={() => goToLink("https://www.linkedin.com/in/diegovsouza99/")}>© Copyright</Text>
                <Text className="copyright text-secundary"
                    fontSize='0.875rem' onClick={() => goToLink("https://www.linkedin.com/in/diegovsouza99/")} cursor='pointer'>Diego Vieira</Text>
                <FaLinkedin cursor='pointer' onClick={() => goToLink("https://www.linkedin.com/in/diegovsouza99/")} />
                <Text cursor='pointer' onClick={() => goToLink("https://www.linkedin.com/in/marcio-fernandes-5252061a9/")} className="copyright text-secundary"
                    fontSize='0.875rem'>
                    and Marcio Fernandes
                </Text>
                <FaLinkedin cursor='pointer' onClick={() => goToLink("https://www.linkedin.com/in/marcio-fernandes-5252061a9/")} />
            </Flex>
        </footer>
    );
}
