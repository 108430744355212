import React, { useState, useEffect } from 'react';
import { Flex, Text } from "@chakra-ui/react";
import { motion, AnimatePresence } from 'framer-motion';
import LoadingSpinner from "./LoadingSpinner";

const messages = [
    "Passando as marchas...",
    "Ajeitando a corrente...",
    "Inflando os pneus...",
    "Lubrificando as engrenagens...",
    "Ajustando os freios...",
    "Verificando o quadro...",
    "Ajustando o selim...",
    "Verificando os parafusos...",
    "Calibrando os pneus...",
    "Testando a suspensão..."
];

const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
};

export default function LoadingPage() {
    const [message, setMessage] = useState(messages[0]);

    useEffect(() => {
        const randomMessage = messages[Math.floor((Math.random() * messages.length))];
        setMessage(randomMessage);
    }, []);

    return (
        <main>
            <Flex justifyContent='center' alignItems='center' w='100%' height='100vh' padding='5rem 0' direction='column'>
                <LoadingSpinner />
                <AnimatePresence >
                    <motion.div
                        key={message}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={fadeVariants}
                        transition={{ duration: 1 }}
                    >
                        <Text fontSize="xl" mt="2rem">{message}</Text>
                    </motion.div>
                </AnimatePresence>
            </Flex>
        </main>
    );
}
