import { useMediaQuery } from "@chakra-ui/react";

const IsMobile = () => {
    const [isMobile] = useMediaQuery("(min-width: 768px)");
    return isMobile;
}

const IsToach = () => {
    const [isToach] = useMediaQuery("(min-width: 992px)");
    return isToach;
}


export { IsMobile, IsToach }