import { Text, Flex, Image, Box, Input, Button } from '@chakra-ui/react';
import './Features.css';
import { useState } from 'react';
import { sendMessage } from '../../../utils/SendMessage';

export default function Features() {
    const [message, setMessage] = useState('')

    return (
        <Flex id='features' mt={['20rem', '20rem', '15rem', '15rem', '15rem']} gap={['2rem', '2rem', '2rem', '2rem', '0']} height='100%' as='section'
            alignItems='center' flexWrap='wrap' justifyContent='space-between' padding={['2rem', '2rem 2.5rem', '2.5rem', '3rem', '4rem']}>
            <Flex direction='column' w={['100%', '100%', '100%', '70%', '70%']}>
                <Text color='white'>MENSAGEM</Text>
                <Text color='black' fontWeight='600' fontSize='3rem'>Nos faça uma pergunta</Text>
                <Text fontSize='1rem' as='span' >Escreva sua perunta e você será redirecionado para nosso whatsapp onde um de nossos atendentes vai lhe atender.</Text>
            </Flex>
            <Flex direction='column' mb={['0', '0', '0', '0', '5rem']}  height='100%' gap='1rem' w={['100%', '100%', '100%', '80%', '30%']}>
                <Input  background='white' color='black'  onChange={(e)=>setMessage(e.currentTarget.value)} zIndex={5}   placeholder='Digite aqui...' />
                <Button background='black' fontFamily='Poppins' color='white' fontSize='1rem' onClick={()=>sendMessage(message)} >Enviar</Button>
            </Flex>
        </Flex>
    );
}
