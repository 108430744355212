import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import { CgDanger } from "react-icons/cg";
import { InputHTMLAttributes, JSXElementConstructor, ReactElement, ReactNode } from "react";
import { Button, useColorMode } from "@chakra-ui/react";
import './ButtonComponent.css'

interface ButtonComponentProps extends InputHTMLAttributes<HTMLButtonElement> {
    labelName: string;
    full: boolean;
    isLoading?: boolean;
    icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
}

type PropsType = "button" | "reset" | "submit" | undefined;

export default function ButtonComponent({
    labelName,
    full,
    isLoading = false,
    icon,
    ...props
}: ButtonComponentProps) {
    const { colorMode } = useColorMode();

    const { type: propType, size: propSize, disabled , ...restProps } = props;

    return (
        <Button rightIcon={icon} id='button-component' isDisabled={disabled} isLoading={isLoading} {...restProps} type={propType as PropsType}  size={String(propSize)} height='2.5rem' className={full ? 'full' : ''} background='white' >
            {labelName}
        </Button>
    );
}
