import axios from "axios";
import { store } from "../../store";
import { Creators as UserActions } from "../../store/modules/user/actions";

const localhost = 'http://localhost:3333';
const production = 'https://casa-das-bicicletas-app-jjnfe.ondigitalocean.app/';

const token = localStorage.getItem("@casa_das_bicicletas_token");
export let api = axios.create({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer: ${token}`,
    "ngrok-skip-browser-warning": 'true',
  },
  baseURL: production,
  validateStatus: (status) => {
    if (status === 401) {
      store.dispatch(UserActions.logout());
      if (window.location.pathname !== "/login") {
        window.location.pathname = "/login";
      }
    }
    return status >= 200 && status < 300;
  },
});

export let apiViaCep = axios.create({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer: ${token}`
  },
  validateStatus: (status) => {
    if (status === 401) {
      store.dispatch(UserActions.logout());
      if (window.location.pathname !== "/login") {
        window.location.pathname = "/login";
      }
    }
    return status >= 200 && status < 300;
  },
});

