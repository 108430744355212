import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserStore } from '../../Domain/Model/Token';
import NotFoundView from '../../Presentation/Pages/NotFound/NotFoundView';
import LoadingPage from '../../Presentation/Components/Notification/LoadingPage';

const HomeView = lazy(() => import('../../Presentation/Pages/Home/HomeView'));
const LoginView = lazy(() => import('../../Presentation/Pages/Login/LoginView'));
const UserPage = lazy(() => import('../../Presentation/Pages/UserPage/UserPage'));
const ShopView = lazy(() => import('../../Presentation/Pages/Shop/ShopView'));
const ProductView = lazy(() => import('../../Presentation/Pages/Product/ProductView'));
const ShopCarView = lazy(() => import('../../Presentation/Pages/ShopCar/ShopCarView'));
const RegisterView = lazy(() => import('../../Presentation/Pages/Register/RegisterView'));
const CheckoutPageView = lazy(() => import('../../Presentation/Pages/CheckOutPage/CheckoutPageView'));
const AdminView = lazy(() => import('../../Presentation/Pages/Admin/AdminView'));
const MyRequestsView = lazy(() => import('../../Presentation/Pages/MyRequests/MyRequestsView'));
const LocationView = lazy(() => import('../../Presentation/Pages/Location/LocationView'));

const Router: React.FC = () => {
  const { user } = useSelector((state: UserStore) => state);

  return (
    <Suspense fallback={<LoadingPage/>}>
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='/home/' element={<HomeView />} />
        <Route path='/home/loja/' element={<ShopView />} />
        <Route path='/home/loja/:productname/:id' element={<ProductView />} />
        <Route path='/home/carrinho/' element={<ShopCarView />} />
        <Route path='/checkout' element={<CheckoutPageView />} />
        <Route path='/meus-pedidos/' element={<MyRequestsView />} />
        <Route path='/perfil/' element={<UserPage />} />
        <Route path='/login/' element={<LoginView />} />
        <Route path='/register/' element={<RegisterView />} />
        <Route path='/localizacao/' element={<LocationView />} />
        <Route path='/contato/' element={<LocationView />} />
        {user && user?.role?.value === 'ADMIN' && <Route path='/admin/' element={<AdminView />} />}
        <Route path='*' element={<NotFoundView />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
