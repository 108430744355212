import { ProductGet, ProductPost, ProductPut, ProductVariablePut } from "../../Domain/Model/Product";
import { api } from "../Services/api";
import ProductDataSource from "../DataSource/ProductDataSource";
import { ProductAPIEntity, ProductPagAPIEntity, ProductVariableAPIEntity } from "../Entity/ProductAPIEntity";


export default class ProductAPIDataSourceImpl implements ProductDataSource {

  async getProducts(params?: ProductGet): Promise<ProductAPIEntity[]> {
    try {
      let url = '/api/v1/products';
      let isFirstParam = true;

      if (params) {
        params.sorted_by = undefined
        params.discount = undefined
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            const value = params[key];
            if (value !== undefined && value !== null) {
              if (isFirstParam) {
                url += `?${key}=${value}`;
                isFirstParam = false;
              } else {
                url += `&${key}=${value}`;
              }
            }
          }
        }
      }

      const { data } = await api.get(url);
      return data;
    } catch (error: any) {
      console.log(error);
      return [] as ProductAPIEntity[];
    }
  }

  async getProductsPag(params?: ProductGet, admin?: boolean): Promise<ProductPagAPIEntity> {
    try {
      let url = '/api/v1/products';
      let isFirstParam = true;
      
      admin && (url = url+ '/admin')

      if (params) {
        params.sorted_by = undefined
        params.discount = undefined
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            const value = params[key];
            if (value !== undefined && value !== null) {
              if (isFirstParam) {
                url += `?${key}=${value}`;
                isFirstParam = false;
              } else {
                url += `&${key}=${value}`;
              }
            }
          }
        }
      }


      const { data } = await api.get(url);
      return data;
    } catch (error: any) {
      console.log(error);
      return {} as ProductPagAPIEntity;
    }
  }

  async postProducts(postData: ProductPost): Promise<ProductAPIEntity> {
    try {
      const { product_variables, ...product } = postData

      const { data: productData } = await api.post('/api/v1/products/', product)

      let variablesData: ProductVariableAPIEntity[] = [];

      product_variables.map(async variable => {
        try {
          let formData = new FormData();
          variable.product_id = productData.id
          Object.entries(variable).forEach(([key, value]) => {
            formData.append(key, value);
          });

          const { data } = await api.post('/api/v1/product_variables', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          variablesData.push(data)
        } catch (error) {
          throw new Error("")
        }
      })

      productData.product_variables = variablesData

      return productData;
    } catch (error: any) {
      console.log(error)
      throw new Error
    }
  }
  async putProducts(putData: ProductPut): Promise<ProductAPIEntity> {
    try {
      const { product_variables } = putData
      console.log('entrou no repository', putData);

      const { data: productData } = await api.put(`/api/v1/products/${putData.id}`, putData)
      console.log('entrou no repository, resposta da api', productData);

      let variablesData: ProductVariableAPIEntity[] = [];
      product_variables.map(async variable => {
        try {
          let formData = new FormData();
          variable.product_id = productData.id
          Object.entries(variable).forEach(([key, value]) => {
            formData.append(key, value);
          });

          // const { image_link, ...restVariable } = variable
          if (variable.id) {
            const { data } = await api.put(`/api/v1/product_variables/${variable.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            variablesData.push(data)
          } else {
            formData.delete('id')
            const { data } = await api.post(`/api/v1/product_variables`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            variablesData.push(data)
          }

        } catch (error) {
          throw new Error("")
        }
      })

      productData.product_variables = variablesData

      return productData;

    } catch (error: any) {
      console.log(error)
      return {} as ProductAPIEntity;
    }
  }
  async deleteProducts(ProductId: string): Promise<ProductAPIEntity> {
    try {
      const { data } = await api.delete(`/api/v1/products/${ProductId}`)
      return data;
    } catch (error: any) {
      console.log(error)
      throw new Error
    }
  }



}
