import { CategoryGet, CategoryPost, CategoryPut } from "../../Domain/Model/Category";
import { api } from "../Services/api";
import CategoryDataSource from "../DataSource/CategoryDataSource";
import { CategoryAPIEntity } from "../Entity/CategoryAPIEntity";

export default class CategoryAPIDataSourceImpl implements CategoryDataSource {
  async getCategorys(params: CategoryGet): Promise<CategoryAPIEntity[]> {
    try {
      // let url = '/api/v1/category';
      // let isFirstParam = true;

      // if (params) {
      //   for (const key in params) {
      //     if (Object.prototype.hasOwnProperty.call(params, key)) {
      //       const value = params[key];
      //       if (value !== undefined && value !== null) {
      //         if (isFirstParam) {
      //           url += `?${key}=${value}`;
      //           isFirstParam = false;
      //         } else {
      //           url += `&${key}=${value}`;
      //         }
      //       }
      //     }
      //   }
      // }
      let url = '/api/v1/categories';

      const { data } = await api.get(url);
      return data;
    } catch (error: any) {
      console.log(error);
      return [] as CategoryAPIEntity[];
    }
  }

  async postCategorys(postData: CategoryPost): Promise<CategoryAPIEntity> {
    try {
      const formData = new FormData();
  
      Object.entries(postData).forEach(([key, value]) => {
        formData.append(key, value);
      });
  
      const { data } = await api.post('/api/v1/categories', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
  
      return data;
    } catch (error: any) {
      console.log(error);
      throw new Error
    }
  }
  async putCategorys(putData: CategoryPut): Promise<CategoryAPIEntity> {
    try {
      const formData = new FormData();
      const {id, created_at, updated_at, deleted_at, ...restData} = putData
      Object.entries(restData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const { data } = await api.put(`/api/v1/categories/${id}`, formData, { headers: {
        'Content-Type': 'multipart/form-data', 
      },})
      return data;
    } catch (error: any) {
      console.log(error)
      throw new Error
    }
  }
  async deleteCategorys(categoryId: string): Promise<CategoryAPIEntity> {
    try {
      const { data } = await api.delete(`/api/v1/categories/${categoryId}`)
      return data;
    } catch (error: any) {
      console.log(error)
      throw new Error
    }
  }



}
