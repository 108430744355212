import { Flex, Box, Text, Image } from '@chakra-ui/react';
import { CategoryModel } from '../../../main/hooks/useCategoryModel';
import MapComponent from '../../Components/Map/MapComponent';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import image404 from '../../assets/images/404.webp'
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import './NotFoundView.css'
export default function NotFoundView() {
  const history = useNavigate()
  return (
    <main>
      <Flex mb='-15rem' w='100%' id='page-404' position='relative' >
        <Image src={image404} w='100%' height='100vh'  objectFit='cover' />
        <Flex id='overlay-404' textAlign='center' top='0'  left='0' position='absolute' justifyContent='center' alignItems='center' direction='column' w='100%' height='100%'>
          <Text className='text-primary' fontWeight='600' fontSize='3.5rem' >404</Text>
          <Text fontWeight='600' fontSize={['1.5rem' , '2rem' , '3.5rem' , '3.5rem' , '3.5rem' ]}color='white' >Parece que deu algo errado</Text>
          <ButtonComponent labelName='Voltar ao início' full onClick={()=>history('/home')} width='10rem' />
        </Flex>
      </Flex>
    </main>
  );
}
