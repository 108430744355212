/* eslint-disable no-console */
import { createContext, ReactNode, useContext, useState } from "react";
import { ProductShopCar, ShopCar } from "../../Domain/Model/ShopCar";

interface ShopCarContextProps {
    onAddProduct: (productShopCar: ProductShopCar) => void;
    onChangeShopCarProduct: (id: string, quantity: number) => void;
    onRemoveProduct: (id: string) => void;
    onResetCar: () => void;
    ShopCar: ShopCar
    loadingState: boolean
}

interface Props {
    children: ReactNode;
}

const ShopCarContext = createContext({} as ShopCarContextProps);

function ShopCarProvider({ children }: Props) {
    const [ShopCar, setShopCar] = useState<ShopCar>(() => {
        let shopLocal = localStorage.getItem('@shopCar')
        if (shopLocal) {
            return JSON.parse(shopLocal)
        }
        return undefined
    });

    const [loadingState, setLoadingState] = useState(false)


    function onAddProduct(productShopCar: ProductShopCar) {
        if (ShopCar) {
            let products = ShopCar.products
            let newProducts = [...products]
            let currentProduct = newProducts.find(item => item.id === productShopCar.id)
            if (currentProduct) {
                newProducts.map(item => {
                    if (item.id === productShopCar.id) {
                        let newQuantity = item.qtd + productShopCar.qtd
                        if (newQuantity <= item.quantity)
                            item.qtd = newQuantity
                        else
                            item.qtd = item.quantity

                    }
                })
            } else {
                newProducts.push(productShopCar)
            }
            ShopCar.products = newProducts
            localStorage.setItem("@shopCar", JSON.stringify(ShopCar))
            setShopCar(ShopCar);
        } else {
            let newProducts = []
            newProducts.push(productShopCar)
            let shopcar = { products: newProducts }
            localStorage.setItem("@shopCar", JSON.stringify(shopcar))
            setShopCar(shopcar);
        }
    }

    function onChangeShopCarProduct(id: string, quantity: number) {
        if (ShopCar) {
            let products = ShopCar.products
            products.map(item => {
                if (item.id === id)
                    item.qtd = quantity
            })
            ShopCar.products = products
            localStorage.setItem("@shopCar", JSON.stringify(ShopCar))
            setLoadingState(!loadingState)
            setShopCar(ShopCar);
        }
    }

    function onRemoveProduct(id: string) {
        if (ShopCar) {
            let newProducts = ShopCar.products.filter(item => item.id !== id)
            let newShopCar = { products: newProducts }
            localStorage.setItem("@shopCar", JSON.stringify(newShopCar))
            setShopCar(newShopCar)
        }
    }

    function onResetCar() {
        let shopCardReset = { products: [] }
        localStorage.setItem("@shopCar", JSON.stringify(shopCardReset))
        setShopCar(shopCardReset)
    }

    return (
        <ShopCarContext.Provider value={{
            onAddProduct,
            onChangeShopCarProduct,
            onRemoveProduct,
            onResetCar,
            ShopCar,
            loadingState
        }}>
            {children}
        </ShopCarContext.Provider>
    );
}

const useShopCar = (): ShopCarContextProps => {
    const context = useContext(ShopCarContext);

    if (!context) {
        throw new Error("");
    }

    return context;
};

export { useShopCar, ShopCarProvider };
