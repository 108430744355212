import './Header.css'
import {
    Button, Flex, ListItem, UnorderedList, Image,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Box,
} from "@chakra-ui/react";
import { IsMobile } from "../../../utils/IsMobile";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png'
import Sidebar from "../SideBar/SideBar";
import { IoSearchSharp } from "react-icons/io5";
import { CategoryModel } from '../../../main/hooks/useCategoryModel';
import { FaShoppingCart, FaMapMarkerAlt, FaUser } from "react-icons/fa";
import { useShopCar } from '../../../main/hooks/useShopCar';
import { useSelector } from 'react-redux';
import { UserStore } from '../../../Domain/Model/Token';
import { AtavatarMenu } from '../Avatar/AvatarMenu';

export default function Header() {
    const history = useNavigate()
    const { ShopCar } = useShopCar()
    const { onChangeValue } = CategoryModel()
    const { user } = useSelector((state: UserStore) => state);

    const goToStore = () => {
        onChangeValue(undefined)
        history('/home/loja/')
    }

    const goToAbout = () => {
        history('/home/')
        setTimeout(() => window.location.hash = '#about', 100)
    }


    const renderHeader = () => {
        if (IsMobile())
            return (<>
                <Flex >
                    <ListItem cursor='pointer' onClick={() => history('/home')}><Image src={logo} w='12rem' /></ListItem>
                </Flex>
                <Flex  >
                    <ListItem><Button variant='link' fontSize='1rem' onClick={() => history('/home')}>HOME</Button></ListItem>
                    <ListItem><Button variant='link' fontSize='1rem' onClick={() => goToStore()}>LOJA</Button></ListItem>
                    <ListItem><Button variant='link' fontSize='1rem' onClick={() => goToAbout()}>SOBRE</Button></ListItem>
                    <ListItem><Button variant='link' fontSize='1rem' onClick={() => history('/localizacao')}>CONTATO</Button></ListItem>
                    <Flex ml='3rem' justifyContent='space-between' alignItems='center' gap='1.5rem'>
                        {user?.id ?
                            <AtavatarMenu />
                            :
                            <Popover
                                placement='bottom'
                            >
                                <PopoverTrigger>
                                    <ListItem><FaUser className='headerSvg' /></ListItem>
                                </PopoverTrigger>
                                <PopoverContent className='popover' w='100%'>
                                    <PopoverArrow />
                                    <PopoverBody >
                                        <Button variant='link' onClick={() => history('/register')}>Cadastre-se</Button>
                                        <Button variant='link' onClick={() => history('/login')}>Login</Button>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>}
                        <ListItem onClick={() => history('/localizacao')}><FaMapMarkerAlt className='headerSvg' /></ListItem>
                        <ListItem onClick={() => goToStore()}><IoSearchSharp className='headerSvg' /></ListItem>
                        <ListItem onClick={() => history('/home/carrinho/')} cursor='pointer' position='relative'>
                            {ShopCar?.products.length > 0 && <Box id='shop-car-overlay' >{ShopCar.products.length}</Box>}
                            <FaShoppingCart className='headerSvg' />
                        </ListItem>
                    </Flex>
                </Flex>


            </>)
        else
            return (<>
                <Sidebar />
                {user?.id &&
                    <AtavatarMenu />}
                <ListItem mr='1rem' onClick={() => history('/home/carrinho/')} cursor='pointer' position='relative'>
                    {ShopCar?.products.length > 0 && <Box id='shop-car-overlay' >{ShopCar.products.length}</Box>}
                    <FaShoppingCart className='headerSvg' />
                </ListItem>
            </>)
    }
    return (
        <nav>
            <UnorderedList id='header-ul' padding='1rem'>
                {renderHeader()}
            </UnorderedList>
        </nav>
    );
}
