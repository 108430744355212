import './AvatarMenu.css'
import { AiFillProfile } from "react-icons/ai";
import { MdOutlineExitToApp } from "react-icons/md";
import { Avatar, Box, MenuGroup, MenuDivider } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import { UserStore } from "../../../Domain/Model/Token";
import { Creators as Admin } from '../../../store/modules/admin/actions'
import { Creators as User } from '../../../store/modules/user/actions'
import ToggleColorButton from "../ToggleColorButton/ToggleColorButton";
import { GiShoppingBag } from "react-icons/gi";
export const AtavatarMenu = () => {
    const { admin, user } = useSelector((state: UserStore) => state);
    const history = useNavigate();
    const dispatch = useDispatch();

    function LoggoutAccount() {
        dispatch(User.logout())
        history("/home/");
        setTimeout(()=>window.location.reload(),500)
        
    }

    return (
        <>

            <Box
                display="flex"
                color="white"
                alignItems={"center"}
                verticalAlign="center"
                zIndex={5}
            >

                <Menu>
                    <MenuButton
                        as={Avatar}
                        size='sm'
                        aria-label="Options"
                        icon={<Avatar size='sm' name={user.name} />}
                        variant="outline"
                        border="none"
                        colorScheme="white"
                        cursor={'pointer'}
                    />

                    <MenuList
                        className="menulist"
                        background="white"
                        padding="0.5rem 0"
                        color="#0B2A45"
                    >

                        <MenuItem
                            icon={<GiShoppingBag />}
                        onClick={()=>history('/meus-pedidos/')}
                        >
                            Meus pedidos
                        </MenuItem>


                        <MenuItem
                            icon={<AiFillProfile />}
                        onClick={()=>history('/perfil/')}
                        >
                            Perfil
                        </MenuItem>


                        <MenuItem
                            icon={<MdOutlineExitToApp />}
                            onClick={() => {
                                LoggoutAccount();
                            }}
                        >
                            Sair
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>

        </>
    );
};
